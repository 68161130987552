import TokenMigration from "./TokenMigration";
import styled from "styled-components";
import "./MigrateNav.scss";
const NavMigrate = () => {
  return (
    <Wrapper>
      <TokenMigration />
    </Wrapper>
  );
};
export default NavMigrate;
const Wrapper = styled.div`
  max-width: 1320px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;

  @media screen and (max-width: 1320px) {
    width: calc(100vw - 48px);
  }
  @media screen and (max-width: 700px) {
    width: calc(100vw - 32px);
  }
`;
