import { useCallback, useEffect, useState } from "react";
import { useChainId } from "lib/chains";
import axios from "axios";
import { DEFAULT_PAGE_SIZE_POSITIONS } from "./useUserPosition";
const useUserMigrationNavi = (account, params) => {
  const { pageSize = DEFAULT_PAGE_SIZE_POSITIONS } = params || {};
  const [histories, setHistories] = useState([]);
  const [historiesDataIsLoading, setHistoriesDataIsLoading] = useState(true);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  useEffect(() => {
    const fetchIds = async () => {
      try {
        const { data: responseData } = await axios.get(
          `https://api.mummy.finance/layerzero/bridge/histories/${account}`,
          {
            params: {
              type: "ALL",
              page,
              pageSize,
              fromChainId: 112,
              toChainId: 332,
            },
          }
        );

        if (responseData?.data) {
          const { rows = [], count } = responseData?.data;
          setHistories(rows);
          setCount(count);
          setHistoriesDataIsLoading(false);
        }
      } catch (error) {}
    };
    fetchIds();
    const interval = setInterval(async () => {
      fetchIds();
    }, 7000);
    return () => clearInterval(interval);
  }, [account, page, pageSize]);

  return { histories, count, currentPage: page, setCurrenPage: setPage, historiesDataIsLoading };
};

export default useUserMigrationNavi;
