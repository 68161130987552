import React, { useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";

import { getContract } from "config/contracts";

import Modal from "components/Modal/Modal";
import Footer from "components/Footer/Footer";

import Token from "abis/Token.json";
import Vester from "abis/Vester.json";
import RewardTracker from "abis/RewardTracker.json";
import RewardRouter from "abis/RewardRouter.json";

import { FaCheck, FaTimes } from "react-icons/fa";

import { Trans, t } from "@lingui/macro";

import "./BeginAccountTransfer.css";
import "./BeginAccountTransferTheme.scss";
import { callContract, contractFetcher } from "lib/contracts";
import { approveTokens } from "domain/tokens";
import { useChainId } from "lib/chains";
import { StyledInput } from "components/Referrals/AddAffiliateCode";
import { useThemeContext } from "contexts/ThemeProvider";

export function ValidationRow({ isValid, children }) {
  return (
    <div className="ValidationRow">
      <div className="ValidationRow-icon-container">
        {isValid && <FaCheck className="ValidationRow-icon" />}
        {!isValid && <FaTimes className="ValidationRow-icon" />}
      </div>
      <div>{children}</div>
    </div>
  );
}

export default function BeginAccountTransfer(props) {
  const { setPendingTxns, connectWallet } = props;
  const { active, library, account } = useWeb3React();
  const { chainId } = useChainId();
  const { lightThemeClassName } = useThemeContext();

  const [receiver, setReceiver] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [isTransferSubmittedModalVisible, setIsTransferSubmittedModalVisible] = useState(false);
  let parsedReceiver = ethers.constants.AddressZero;
  if (ethers.utils.isAddress(receiver)) {
    parsedReceiver = receiver;
  }

  const gmxAddress = getContract(chainId, "GMX");
  const gmxVesterAddress = getContract(chainId, "GmxVester");
  const glpVesterAddress = getContract(chainId, "GlpVester");

  const rewardRouterAddress = getContract(chainId, "RewardRouter");

  const { data: gmxVesterBalance } = useSWR([active, chainId, gmxVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(library, Token),
    refreshInterval: 10000,
  });

  const { data: glpVesterBalance } = useSWR([active, chainId, glpVesterAddress, "balanceOf", account], {
    fetcher: contractFetcher(library, Token),
    refreshInterval: 10000,
  });

  const stakedGmxTrackerAddress = getContract(chainId, "StakedGmxTracker");
  const { data: cumulativeGmxRewards } = useSWR(
    [
      `cumulativeGmxRewards:${[active, parsedReceiver]}`,
      chainId,
      stakedGmxTrackerAddress,
      "cumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );

  const stakedGlpTrackerAddress = getContract(chainId, "StakedGlpTracker");
  const { data: cumulativeGlpRewards } = useSWR(
    [
      `cumulativeGlpRewards:${[active, parsedReceiver]}`,
      chainId,
      stakedGlpTrackerAddress,
      "cumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeGmxRewards } = useSWR(
    [
      `transferredCumulativeGmxRewards:${[active, parsedReceiver]}`,
      chainId,
      gmxVesterAddress,
      "transferredCumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: transferredCumulativeGlpRewards } = useSWR(
    [
      `transferredCumulativeGlpRewards:${[active, parsedReceiver]}`,
      chainId,
      glpVesterAddress,
      "transferredCumulativeRewards",
      parsedReceiver,
    ],
    {
      fetcher: contractFetcher(library, Vester),
      refreshInterval: 10000,
    }
  );

  const { data: pendingReceiver } = useSWR(
    [`pendingReceiver:${(active, account)}`, chainId, rewardRouterAddress, "pendingReceivers", account],
    {
      fetcher: contractFetcher(library, RewardRouter),
      refreshInterval: 10000,
    }
  );

  const { data: gmxAllowance } = useSWR(
    [
      `gmxAllowance:${[active, account, stakedGmxTrackerAddress]}`,
      chainId,
      gmxAddress,
      "allowance",
      account,
      stakedGmxTrackerAddress,
    ],
    {
      fetcher: contractFetcher(library, Token),
      refreshInterval: 10000,
    }
  );

  const { data: gmxStaked } = useSWR(
    [
      `depositBalances:${[account, active, gmxAddress]}`,
      chainId,
      stakedGmxTrackerAddress,
      "depositBalances",
      account,
      gmxAddress,
    ],
    {
      fetcher: contractFetcher(library, RewardTracker),
      refreshInterval: 10000,
    }
  );

  const needApproval = gmxAllowance && gmxStaked && gmxStaked.gt(gmxAllowance);

  const hasVestedGmx = gmxVesterBalance && gmxVesterBalance.gt(0);
  const hasVestedGlp = glpVesterBalance && glpVesterBalance.gt(0);
  const hasStakedGmx =
    (cumulativeGmxRewards && cumulativeGmxRewards.gt(0)) ||
    (transferredCumulativeGmxRewards && transferredCumulativeGmxRewards.gt(0));
  const hasStakedGlp =
    (cumulativeGlpRewards && cumulativeGlpRewards.gt(0)) ||
    (transferredCumulativeGlpRewards && transferredCumulativeGlpRewards.gt(0));
  const hasPendingReceiver = pendingReceiver && pendingReceiver !== ethers.constants.AddressZero;

  const getError = () => {
    if (!account) {
      return `Wallet is not connected`;
    }
    if (hasVestedGmx) {
      return `Vested MMY not withdrawn`;
    }
    if (hasVestedGlp) {
      return `Vested MLP not withdrawn`;
    }
    if (!receiver || receiver.length === 0) {
      return `Enter Receiver Address`;
    }
    if (!ethers.utils.isAddress(receiver)) {
      return `Invalid Receiver Address`;
    }
    if (hasStakedGmx || hasStakedGlp) {
      return `Invalid Receiver`;
    }
    if ((parsedReceiver || "").toString().toLowerCase() === (account || "").toString().toLowerCase()) {
      return `Self-transfer not supported`;
    }

    if (
      (parsedReceiver || "").length > 0 &&
      (parsedReceiver || "").toString().toLowerCase() === (pendingReceiver || "").toString().toLowerCase()
    ) {
      return `Transfer already initiated`;
    }
  };

  const isPrimaryEnabled = () => {
    const error = getError();
    if (error) {
      return false;
    }
    if (isApproving) {
      return false;
    }
    if (isTransferring) {
      return false;
    }
    return true;
  };

  const getPrimaryText = () => {
    const error = getError();
    if (error) {
      return error;
    }
    if (needApproval) {
      return `Approve MMY`;
    }
    if (isApproving) {
      return `Approving...`;
    }
    if (isTransferring) {
      return `Transferring...`;
    }

    return `Begin Transfer`;
  };

  const onClickPrimary = () => {
    if (needApproval) {
      approveTokens({
        setIsApproving,
        library,
        tokenAddress: gmxAddress,
        spender: stakedGmxTrackerAddress,
        chainId,
      });
      return;
    }

    setIsTransferring(true);
    const contract = new ethers.Contract(rewardRouterAddress, RewardRouter.abi, library.getSigner());

    callContract(chainId, contract, "signalTransfer", [parsedReceiver], {
      sentMsg: `Transfer submitted!`,
      failMsg: `Transfer failed.`,
      setPendingTxns,
    })
      .then(async (res) => {
        setIsTransferSubmittedModalVisible(true);
      })
      .finally(() => {
        setIsTransferring(false);
      });
  };

  const completeTransferLink = `/complete_account_transfer/${account}/${parsedReceiver}`;
  const pendingTransferLink = `/complete_account_transfer/${account}/${pendingReceiver}`;

  return (
    <div className={`BeginAccountTransfer Page page-layout ${lightThemeClassName}`}>
      <Modal
        isVisible={isTransferSubmittedModalVisible}
        setIsVisible={setIsTransferSubmittedModalVisible}
        label={`Transfer Submitted`}
      >
        <span>Your transfer has been initiated.</span>
        <br />
        <br />
        <Link className="default-btn full" to={completeTransferLink}>
          <span>Continue</span>
        </Link>
      </Modal>
      <div className="Page-title-section">
        <div className="Page-title" style={{ fontSize: "24px" }}>
          <span>Transfer Account</span>
        </div>
        <div className="Page-description">
          <span style={{ textDecoration: "none" }}>
            Please note that this transfer method is intended for full account transfers only. The following rules
            apply:
            <br />
            - This transfer will move all your MMY, esMMY, MLP, and Multiplier Points to your new account.
            <br />
            - Transfers are only supported if the receiving account has not staked MMY or MLP tokens before.
            <br />- This transfer is one-way only, meaning you will not be able to transfer staked tokens back to the
            sending account.
          </span>
        </div>
        {hasPendingReceiver && (
          <div className="Page-description">
            <span>
              You have a <Link to={pendingTransferLink}>pending transfer</Link> to {pendingReceiver}.
            </span>
          </div>
        )}
      </div>
      <div className="Page-content">
        <div className="input-form">
          <div
            style={{
              marginBottom: 24,
            }}
            className="input-row"
          >
            <label className="input-label">
              <span>Receiver Address</span>
            </label>
            <div>
              <StyledInput
                placeholder="Enter Address"
                type="text"
                value={receiver}
                onChange={(e) => setReceiver(e.target.value)}
                className="text-input"
              />
            </div>
          </div>
          <div className="BeginAccountTransfer-validations">
            <ValidationRow isValid={!hasVestedGmx}>
              <span>Sender has withdrawn all tokens from MMY Vesting Vault</span>
            </ValidationRow>
            <ValidationRow isValid={!hasVestedGlp}>
              <span>Sender has withdrawn all tokens from MLP Vesting Vault</span>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedGmx}>
              <span>Receiver has not staked MMY tokens before</span>
            </ValidationRow>
            <ValidationRow isValid={!hasStakedGlp}>
              <span>Receiver has not staked MLP tokens before</span>
            </ValidationRow>
          </div>
          <div className="input-row">
            {account ? (
              <button
                className="App-cta Exchange-swap-button"
                disabled={!isPrimaryEnabled()}
                onClick={() => onClickPrimary()}
              >
                {getPrimaryText()}
              </button>
            ) : (
              <button className="App-cta Exchange-swap-button" onClick={connectWallet}>
                Connect Wallet
              </button>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
