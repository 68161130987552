import mmyIc from "img/ic_mmy_24.svg";
import mlpIc from "img/ic_mlp_24.svg";
import mslpIc from "img/ic_msp-40.svg";
import arrowDownIc from "img/icons/arrow_down.svg";
const TokenMigration = () => {
  return (
    <>
      <div className="token-migration-container">
        <div className="tab-side tab-token">
          <div className="tab-token-detail">
            <div className="title">Migrate MMY, esMMY & vMMY</div>
            <div className="desc">Your tokens will be converted to NAVI and esNAVI.</div>
            <div className="rate">Convert Rate</div>
            <div className="rates-container">
              <div className="rate-content">
                <div className="rate-info">
                  <div className="info origin">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">MMY</span>
                  </div>
                  <div className="arrow">
                    <img alt="" src={arrowDownIc} />
                    <div className="divider" />
                  </div>
                  <div className="info convert">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">NAVI</span>
                  </div>
                </div>
               <button  className="default-btn">Migrate MMY</button>
              </div>
              <div className="rate-content">
                <div className="rate-info">
                  <div className="info origin">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">esMMY</span>
                  </div>
                  <div className="arrow">
                    <img alt="" src={arrowDownIc} />
                    <div className="divider" />
                  </div>
                  <div className="info convert">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">esNAVI</span>
                  </div>
                </div>
               <button  className="default-btn">Migrate esMMY</button>
              </div>
              <div className="rate-content">
                <div className="rate-info">
                  <div className="info origin">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">vMMY</span>
                  </div>
                  <div className="arrow">
                    <img alt="" src={arrowDownIc} />
                    <div className="divider" />
                  </div>
                  <div className="info convert">
                    <img alt="" src={mmyIc} /> 1 <span className="muted">esNAVI</span>
                  </div>
                </div>
               <button  className="default-btn">Migrate vMMY</button>
              </div>
            </div>
          </div>
        </div>
        <div className="tab-side tab-lp">
          <div className="tab-token-detail">
            <div className="title">Migrate MLP</div>
            <div className="desc">Your MLP will be converted to NLP.</div>
            <div className="rate">Convert Rate</div>
            <div className="rates-container">
              <div className="rate-content">
                <div className="rate-info">
                  <div className="info origin">
                    <img alt="" src={mlpIc} /> 1 <span className="muted">MLP</span>
                  </div>
                  <div className="arrow">
                    <img alt="" src={arrowDownIc} />
                    <div className="divider" />
                  </div>
                  <div className="info convert">
                    <img alt="" src={mlpIc} /> 1 <span className="muted">NLP</span>
                  </div>
                </div>
               <button  className="default-btn">Migrate MLP</button>
              </div>
            </div>
          </div>
          <div className="tab-token-detail">
            <div className="title">Migrate MSLP</div>
            <div className="desc">Your MSLP will be converted to NSLP.</div>
            <div className="rate">Convert Rate</div>
            <div className="rates-container">
              <div className="rate-content">
                <div className="rate-info">
                  <div className="info origin">
                    <img alt="" src={mslpIc} /> 1 <span className="muted">MSLP</span>
                  </div>
                  <div className="arrow">
                    <img alt="" src={arrowDownIc} />
                    <div className="divider" />
                  </div>
                  <div className="info convert">
                    <img alt="" src={mslpIc} /> 1 <span className="muted">NSLP</span>
                  </div>
                </div>
               <button  className="default-btn">Migrate MSLP</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TokenMigration;
